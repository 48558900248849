import getEnvVars from "./environment";
import axios, { AxiosRequestConfig } from "axios";
import { storageManager } from "./appStorage";
import APIResponse from "../interfaces/api-requests";
import { CacheKeys } from "./cacheKeys";
import { IToken } from "../interfaces/interfaces";

const { apiUrl } = getEnvVars() ?? { apiUrl: "" };

enum method {
  GET = "GET",
  POST = "POST",
  PUT = "PUT",
  DELETE = "DELETE",
  PATCH = "PATCH",
}

class AppService {
  cancelToken: any;
  formData: any;
  constructor() {
    axios.interceptors.request.use(
      async (config: any) => {
        
        if (storageManager.exists(CacheKeys.TOKEN)) {
          const token = await storageManager.get<IToken>(CacheKeys.TOKEN);

          config.headers.Authorization = `Bearer ${token}`;
        }
        // config.headers["x-host"] = this.subDomain;
        return config;
      },
      (error:any) => Promise.reject(error)
    );

  }

  private static requestConfig(): AxiosRequestConfig {
    return {
      method: "get",
      baseURL: apiUrl,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    };
  }

  private async execute<T>(
    method: method,
    url: string,
    body: any = undefined,
    _default: T,
    headers: any = {}
  ): Promise<APIResponse<T>> {
    // if (typeof this.cancelToken != typeof undefined) {
    //     this.cancelToken.cancel("Operation canceled due to new request.")
    // }
    // this.cancelToken = axios.CancelToken.source()

    let request = AppService.requestConfig();
    request.method = method;
    request.baseURL += url;
    request.headers = { ...request.headers, ...headers };

    // request.cancelToken = this.cancelToken.token
    if (body) request.data = JSON.stringify(body);
    return await axios(request)
      .then((res: any) => new APIResponse<T>(res?.status, res?.data as T))
      .catch((err: any) => {
        return new APIResponse<T>(err?.response?.status, _default, {
          message: err?.response?.data.message,
        });
      });
  }

  private async mediaExecute(
    method: method,
    url: string,
    body: any = undefined
  ) {
    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    };

    if (method === "POST") {
      return axios
        .post(`${apiUrl}${url}`, body, config)
        .then((res: any) => res?.data)
        .catch((err: any) => err);
    }
  }

  async login(data: any) {
    const response = await this.execute(
      method.POST,
      "v1/api/identity/signin",
      data,
      {} as any
    );

    return response;
  }

  async signUp(data: any) {
    return await this.execute(
      method.POST,
      'v1/api/identity/signup',
      data,

      {} as any
    );
  }
  getUserById = async (id:number) => {
    const response = await this.execute(
      method.GET,
      `v1/api/user/${id}`,
      null,
      {} as any
    );
    return response;
  };
  redirectToAuth = (ref:string ,domain:string , protocol:string, path:string)=>{
    window.location.replace(
      `${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_LOGIN_URL}?ref=${ref}&path=${path}&domain=${domain}&protocol=${protocol}`
    );
}
postPublication = async (data: any) => {
    
  const response = await this.execute(
    
    method.POST,
    `v1/api/publication`,
    data,
    
    {} as any
  );

  return response;
}
deletePublication = async (id: number) => {
  const response = await this.execute(
    method.DELETE,
    `v1/api/publication/${id}`,
    null,
    {} as any
  );
  return response;
};
  async redirectOnSuccess(data: any) {
    let request = AppService.requestConfig();
    const url = request.baseURL + "v1/api/identity/exptreturn?" 
    const query = new URLSearchParams(data).toString()
     

    window.location.replace(url + query)
  }

}

export const appService: AppService = new AppService();
