import React, { useEffect, useState } from "react";
import { PrimaryLayout } from "../components";
import {useDispatch, useSelector } from 'react-redux'
import { appService } from "../services/appServices";
import { getUserId } from "../store/user/userSlice";
import { AppDispatch } from "../store/store";

const CategoryList =()=>{
    const [showNotification, setShowNotification]: any = useState(false);
    const dispatch = useDispatch<AppDispatch>()
    const [showMenu, setShowMenu]: any = useState(false);
    const { user }: any = useSelector<any>((state: any) => state.auth);
    const [ currentUser , setCurrentUser] : any = useState()

const getUser =  async (user : any)=>{
  // const response = await appService.getUserById(user)
  // if(response.success()){
  //   setCurrentUser(response.response)
  //  console.log("tested" , response.response)
  // }
    dispatch(getUserId(user?.userId))
  }

useEffect( ()=>{
  user && getUser(user)
  
},[user])

    return (
        <div
      onClick={() => {
        setShowMenu(false);
        setShowNotification(false);
      }}
    >
      <PrimaryLayout
        showNotification={showNotification}
        setShowNotification={setShowNotification}
        showMenu={showMenu}
        setShowMenu={setShowMenu}
        isAuthor={true}
      />
     {/* {user.username} */}
      </div>
      
    )
}

export default CategoryList