// import Link from 'next/link';
// import { useRouter } from 'next/router';
import styles from '../styles/components/Header.module.scss';


import React, { useState } from 'react';
import { GiHamburgerMenu } from 'react-icons/gi';
import { MdOutlineLogout } from 'react-icons/md';

// import { signOut } from 'next-auth/react';
import { useDispatch, useSelector } from 'react-redux';
import { reset } from '../store/user/userSlice';
import Image from './Image';
import {  Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { BsBell, BsBellFill } from 'react-icons/bs';
import { getUserId } from '../store/user/userSlice';
  
export interface IHeader {
  label?: string;
  isAuthor?: boolean;
  handleClick?: any;
  showMenu?: boolean;
  setShowMenu?: any;
  showNotification?: boolean;
  setShowNotification?: any;
}

const Header: React.FC<IHeader> = ({
  handleClick,
  showMenu,
  setShowMenu,
  showNotification,
  setShowNotification,
}) => {
  //const { data: session } = useSession();
  const navigate = useNavigate();
  const [notification, setNotification] = useState(true);
  const dispatch = useDispatch<any>();
  // const [user, setUser] = useState(false);
  const { userInfo }: any = useSelector<any>((state: any) => state.auth);

  // const router = useRouter();

  const logOut = () => {
    dispatch(reset());
    localStorage.clear();
  };

  //###################Todo################################################################################
  // useEffect(() => {
  //   if (session?.accessToken) {
  //     dispatch(googleLogin(session?.accessToken));
  //   }
  // }, [session?.accessToken]);

  const returnUrl = () => {
    navigate(`/`);
    // {
    //   pathname: '/createAccount',
    //   query: { returnUrl: window.location.pathname }
    // }
  };

  return (
    <>
      <header className={` h-20 `}>
        <div className={`    relative flex justify-between items-center py-3`}>
          <div className="w-full">
            <nav className="bg-[#fff7f7] fixed  top-0 z-[100]  flex justify-end items-center w-full md:px-[7.2%] ">
              <div className="w-full">
                <div className="flex items-center h-20 w-full">
                  <div className="px-2 pr-7 flex items-baseline md:items-center justify-between     w-full">
                    <div className="md:hidden flex-[0.1]"></div>

                    <div className="">
                      <Link to={"/"}>
                        <Image
                          media={{ url: "/images/vow_logo.png" }}
                          alt="Vow"
                          width={319}
                          height={40}
                          className=" mt-0.5 cursor-pointer  "
                        />
                      </Link>
                    </div>
                    <div className="">
                      <div className=" flex  md:space-x-2   justify-between items-center  xl:gap-4  mt-2">
                        {/* {userInfo?.id ? (
                          <span
                            onClick={(e) => {
                              e.stopPropagation();
                              setShowMenu(!showMenu);
                              setShowNotification(false);
                            }}
                          >
                            <p
                              className={` md:block fs-16 font_medium font_color_common cursor-pointer ${styles.write_header}`}
                            >
                              {userInfo?.username}
                            </p>
                          </span>
                        ) : (
                          <p
                            className={`hidden md:block fs-16 ml-3 font_medium font_color_common cursor-pointer ${styles.write_header}`}
                            onClick={returnUrl}
                          >
                            Sign In
                          </p>
                        )} */}
                        {showMenu ? (
                          <div
                            className={`${styles.user_information} right-36 absolute  top-16 mt-1 p-5`}
                          >
                            {/* <p className="fs-14 font_color_tertiary font_normal pt-4 cursor-pointer">
                              Manage Subscriptions
                              </p>
                              <p className="fs-14 font_color_tertiary font_normal pt-2 cursor-pointer">
                              Analytics
                            </p> */}

                            {/* <p className="fs-14 font_color_tertiary font_normal pt-2 cursor-pointer">
                              Publications
                            </p> */}

                            <Link to="/Publication">
                              <p className="fs-14 font_color_tertiary font_normal pt-2 cursor-pointer">
                                Publication
                              </p>
                            </Link>
                            <p
                              className="fs-14 font_color_tertiary font_normal pt-2 cursor-pointer"
                              onClick={logOut}
                            >
                              Log Out
                            </p>
                            <div className="flex justify-start items-center mt-4">
                              {userInfo?.profile?.logo?.path ? (
                                <img
                                  src={userInfo?.profile?.logo?.path}
                                  className="h-[29px] w-[29px] rounded-full"
                                  alt=""
                                />
                              ) : null}
                              <p className="fs-14 ml-3 font-semibold font_color_common cursor-pointer">
                                {userInfo?.userInfoname}
                              </p>
                            </div>
                          </div>
                        ) : null}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </nav>
          </div>
        </div>
      </header>
    </>
  );
};

export default Header;
