// import { getProviders, signIn, useSession } from "next-auth/react";
import axios from "axios";
import { lazy, useEffect, useState } from "react";
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";
import { MdEmail } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import getEnvVars from "../services/environment";
import styles from "../styles/pages/createAccount.module.scss";
  import "react-toastify/dist/ReactToastify.css";
import { useForm } from "react-hook-form"
import {  getUserId, login, signUp, storePassword } from "../store/user/userSlice";
  import { ToastContainer, toast } from "react-toastify";
import isFieldEmpty from "../components/validate";
import { appService } from "../services/appServices";
import { useNavigate } from "react-router-dom";

const PrimaryLayout = lazy(() => import("../components/PrimaryLayout"));
const Button = lazy(() => import("../components/Button"));

const { apiUrl } = getEnvVars() ?? { apiUrl: "" };
type formValues={
  username:string,
  email : any,
  message:string,
  password:any,
  confirmpassword : any
}

const CreateAccount = () => {
  const [signingData, setSigningData] = useState({
    email: "",
    password: "",
  });
  const navigate = useNavigate();
  const [errorCheck, setErrorCheck] = useState(false);
  const { message, isError } = useSelector((state: any) => state.auth);
  const [open, setOpen] = useState(false);
  const [toggle, setToggle] = useState(false);
  const [passwordEye, setPasswordEye] = useState(false);
  const [confirmPasswordEye, setConfirmPasswordEye] = useState(false);
  const { isSuccess, user }: any = useSelector<any>((state: any) => state.auth);
  const dispatch = useDispatch<any>();
  const [account, setAccount] = useState(false);

  const [signingUpData, setSigningUpData] = useState({
    username: "",
    email: "",
    password: "",
  });

  const [inputError, setInputError] = useState({
    isError: false,
    type: "email",
    message: "enter valid email",
  });

  const [inputErrorPassword, setInputErrorPassword] = useState({
    isError: false,
    type: "password",
    message: "password is empty",
  });

  const [isSuccessful, setSuccessful] = useState(false);
  const [error, setError] = useState({
    isError: false,
    code: "",
    message: "",
  });

  const [loginRes, setLoginRes] = useState({
    accessToken: "",
    refreshToken: "",
    userId: "",
  });
  const [signupRes, setSignupRes] = useState({
    accessToken: "",
  });

  const searchParams = new URLSearchParams(window.location.search);

  const handlePasswordClick = () => {
    setPasswordEye(!passwordEye);
  };

  const handleConfirmPasswordClick = () => {
    setConfirmPasswordEye(!confirmPasswordEye);
  };

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm<formValues>();
  useEffect(() => {
    if (user && isSuccess) {
      navigate("/Publication");
    }
  }, [isSuccess]);
  const onSubmit = (data: any) => {
    setErrorCheck(true);
    const { email, password } = data;
    const body = { email, password };

    dispatch(login(body));
  };
  const createUser = async (data: any) => {
    const { email, password, username } = data;
    const body = { email, password, username };

    //setToggle(!toggle)

    setErrorCheck(true);
    const response = await appService.signUp(body);
    if (response.success()) {
      const data = { email, password, domain: "" };
      dispatch(login(data));

      dispatch(storePassword(password));
    }
    //dispatch(signUp(body));
    // const fetch = async ()=>{
    //   if(account){
    //     return await appService.login(  "saved")
    //     setToggle(!toggle)
    //   }
    // }
  };
  const handleKeypressSignIn = (e: any) => {
    if (e.key == "Enter") {
      handleSubmit(createUser)();
    }
  };
  const params = {
    domain: searchParams.get("domain"),
    referrer: searchParams.get("referrer"),
    protocol: searchParams.get("protocol"),
    path: searchParams.get("path"),
  };

  const signin = async () => {
    let emailError = false;
    let passwordError = false;
    const payload = {
      ...signingData,
    };

    if (!payload.email.match(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/)) {
      setInputError({
        ...inputError,
        isError: true,
        type: "email",
        message: "Email should be valid",
      });
      emailError = true;
    }

    if (payload.password.length < 1) {
      setInputErrorPassword({
        ...inputErrorPassword,
        isError: true,
        type: "password",
        message: "Password should not be empty",
      });
      passwordError = true;
    }

    if (!emailError && !passwordError) {
      axios({
        method: "post",
        url: apiUrl + "v1/api/identity/public-signin",
        data: payload,
      })
        .then((res) => {
          if (!res.data.error) {
            setLoginRes({
              ...res.data,
            });
            setSuccessful(true);
            toast.success(`Successful Login`);
          } else {
            setError({
              isError: true,
              code: res.data.statusCode,
              message: res.data.message,
            });
          }
        })
        .catch((error) => {
          toast.error(`${error?.response?.data?.message}`, {
            className: " text-white font_semibold",
          });
        });
    }
  };

  const redirect = (x: any) => {

    const query = {
      ...loginRes,
      ...params,
    };
    window.location.replace(
      `${apiUrl}v1/api/identity/redirect-public?accessToken=${query.accessToken}&refreshToken=${query.refreshToken}&domain=${query.domain}&userId=${query.userId}&referrer=${query.referrer}&protocol=${query.protocol}&path=${query.path}`
    );
  };

  isSuccessful && redirect("x");

  //handle toggle
  const toggleEye = () => {
    setOpen(!open);
  };

  return (
    <>
      <PrimaryLayout isAuthor={true} />
      <ToastContainer />
      {!toggle ? (
        <section className="container_width flex gap-8 items-center pt-5 justify-center">
          <aside className=" basis-1/4">
            <div>
              <p className="fs-24 mt-5 font_semibold w-full flex justify-center font_color_common">
                Sign In
              </p>
              <div
                className={`${styles.create_border} mt-5 gap-y-2 flex flex-col items-center p-7 justify-between `}
              >
                <div className="w-full">
                  <p className="fs-14 font_medium font_color_common my-2">
                    Email Address
                  </p>
                  <input
                    type="email"
                    className={`w-full ${styles.sign_in} fs-12 font_normal justify-center font_color_tertiary px-2`}
                    placeholder="Enter your E-Mail Address"
                    value={signingData.email}
                    onChange={(e) => {
                      inputError.isError = false;
                      setSigningData({
                        ...signingData,
                        email: e.target.value,
                      });
                    }}
                  />
                  {inputError.isError && inputError.type === "email" && (
                    <p className="text-[red]">{inputError.message}</p>
                  )}
                  {/* {errors?.email?.type === "required" && (
                  <p className="text-[red]">Email is required</p>
                )} */}
                  {/* {errors?.email?.type === "pattern" && (
                  <p className="text-[red]">Email must be valid</p>
                )} */}
                </div>

                <div className="w-full ">
                  <p className="fs-14 font_medium font_color_common my-2">
                    Password
                  </p>

                  <div className={`w-full relative   flex items-center`}>
                    <input
                      type={!open ? "password" : "text"}
                      className={`w-full border-0 ${styles.sign_in} fs-12 font_normal rounded-full justify-center font_color_tertiary px-2 `}
                      placeholder="Enter your Password"
                      onChange={(e) => {
                        inputErrorPassword.isError = false;
                        setSigningData({
                          ...signingData,
                          password: e.target.value,
                        });
                      }}
                    />

                    {!open ? (
                      <AiFillEye
                        size={19}
                        onClick={toggleEye}
                        className="absolute right-2"
                      />
                    ) : (
                      <AiFillEyeInvisible
                        size={19}
                        onClick={toggleEye}
                        className="absolute right-2"
                      />
                    )}
                  </div>
                  {inputErrorPassword.isError &&
                    inputErrorPassword.type === "password" && (
                      <p className="text-[red]">{inputErrorPassword.message}</p>
                    )}
                  {/* {errors?.password?.type === "required" && (
                  <p className="text-[red]">Password is required</p>
                )} */}
                </div>

                {error.isError && <p className="text-[red]">{error.message}</p>}

                <p className="fs-12 font_normal font_color_tertiary">
                  By signing in, you agree to our publisher agreement
                </p>

                <Button
                  label="Login"
                  onClick={signin}
                  classProperty={`btn_tertiary_1 font_color_tertiary font_normal fs-14 ${styles.continue_button}`}
                ></Button>
                {/* <span>Or</span>

                <Button
                  onClick={() => setToggle(true)}
                  label="Create Account"
                  classProperty={`btn_tertiary_1 font_color_tertiary font_normal fs-14 ${styles.continue_button}`}
                ></Button> */}
              </div>
              <p className="fs-11 font_color_tertiary font_normal mt-5">
                Sign up now, and publishing is free forever. Once you start
                charging for subscriptions, we take 10% in addition to credit
                card transaction fees.
              </p>
            </div>
          </aside>
        </section>
      ) : (
        <section className="container_width flex gap-8 justify-center">
          <aside className="basis-1/4">
            <form onSubmit={onSubmit}>
              <div>
                <p className="fs-24 mt-5 font_semibold w-full flex justify-center font_color_common">
                  Create Account
                </p>
                <div
                  className={`${styles.create_border} mt-5 gap-y-2 flex flex-col items-center p-7 justify-between `}
                >
                  <div className="w-full">
                    <p className="fs-14 font_medium font_color_common my-2">
                      Username
                    </p>
                    <input
                      type="text"
                      className={`w-full ${
                        styles.sign_in
                      } fs-12 font_normal justify-center font_color_tertiary px-2 
                    ${
                      errors.username &&
                      "focus:border-red-500 focus:ring-red-500 border-red-500"
                    }`}
                      placeholder="Enter your Username"
                      {...register("username", {
                        required: "Username is required",
                        minLength: 8,
                      })}
                    />
                    {errors?.username?.type === "minLength" && (
                      <span className="text-sm text-red-500">
                        Username must be 8 letters
                      </span>
                    )}
                    {errors.username && (
                      <span className="text-sm text-red-500">
                        {errors.username.message}
                      </span>
                    )}
                  </div>

                  <div className="w-full">
                    <p className="fs-14 font_medium font_color_common my-2">
                      Email Address
                    </p>
                    <input
                      type="text"
                      className={`w-full ${
                        styles.sign_in
                      } fs-12 font_normal justify-center font_color_tertiary px-2
                    ${
                      errors.email &&
                      "focus:border-red-500 focus:ring-red-500 border-red-500"
                    }`}
                      placeholder="Enter your E-Mail Address"
                      {...register("email", { required: true })}
                    />
                    {errorCheck && isError ? (
                      <span className="text-sm text-red-500">{message}</span>
                    ) : (
                      " "
                    )}

                    {errors.email && (
                      <span className="text-sm text-red-500">
                        Email is required
                      </span>
                    )}
                    {/*{errors.email.message}*/}
                    {/* {isError ? <p className="text-[red]">{message}</p> : ""}
                {errors?.Email?.type === "required" && (
                  <p className="text-[red]">This field is required</p>
                )}
                {errors?.Email?.type === "pattern" && (
                  <p className="text-[red]">Email must be valid</p>
                )} */}
                  </div>

                  <div className="w-full">
                    <p className="fs-14 font_medium font_color_common my-2">
                      Password
                    </p>
                    <div className={`w-full relative   flex items-center`}>
                      <input
                        type={passwordEye === false ? "password" : "text"}
                        className={`w-full border-0 ${
                          styles.sign_in
                        } fs-12 font_normal rounded-full justify-center font_color_tertiary px-2 ${
                          errors.password &&
                          "focus:border-red-500 focus:ring-red-500 border-red-500"
                        }`}
                        placeholder="Enter your Password"
                        {...register("password", {
                          required: "true",
                          minLength: 8,
                        })}
                      />
                      <div className="text-xl absolute top-3 right-3">
                        {passwordEye === false ? (
                          <AiFillEyeInvisible onClick={handlePasswordClick} />
                        ) : (
                          <AiFillEye onClick={handlePasswordClick} />
                        )}
                      </div>

                      {/*{!open ? (
                      <AiFillEye
                        size={19}
                        onClick={toggleEye}
                        className="absolute right-2"
                      />
                    ) : (
                      <AiFillEyeInvisible
                        size={19}
                        onClick={toggleEye}
                        className="absolute right-2"
                      />
                    )}*/}
                    </div>
                    {errors?.password?.type == "required" && (
                      <span className="text-sm text-red-500">
                        Password is Required
                      </span>
                    )}
                    {errors?.password?.type === "minLength" && (
                      <span className="text-sm text-red-500">
                        Password must be of 8 letters
                      </span>
                    )}
                  </div>
                  <div className="w-full">
                    <p className="fs-14 font_medium font_color_common my-2">
                      Confirm Password
                    </p>
                    <div className={`w-full relative   flex items-center`}>
                      <input
                        type={
                          confirmPasswordEye === false ? "password" : "text"
                        }
                        className={`w-full border-0 ${
                          styles.sign_in
                        } fs-12 font_normal rounded-full justify-center font_color_tertiary px-2 ${
                          errors.confirmpassword &&
                          "focus:border-red-500 focus:ring-red-500 border-red-500"
                        } `}
                        placeholder="Confirm your Password"
                        {...register("confirmpassword", { required: true })}
                        onKeyPress={handleKeypressSignIn}
                      />
                      <div className="text-xl absolute top-3 right-3">
                        {confirmPasswordEye === false ? (
                          <AiFillEyeInvisible
                            onClick={handleConfirmPasswordClick}
                          />
                        ) : (
                          <AiFillEye onClick={handleConfirmPasswordClick} />
                        )}
                      </div>
                      {/*{!open ? (
                      <AiFillEye
                        size={19}
                        onClick={toggleEye}
                        className="absolute right-2"
                      />
                    ) : (
                      <AiFillEyeInvisible
                        size={19}
                        onClick={toggleEye}
                        className="absolute right-2"
                      />
                    )}*/}
                    </div>

                    {/*{confirmPassword && (
                    <p className="text-[red]">Password should match</p>
                  )}*/}
                    {errors.confirmpassword && (
                      <span className="text-sm text-red-500">
                        Confirm Password is Required
                      </span>
                    )}
                  </div>

                  <p className="fs-12 font_normal font_color_tertiary">
                    By signing up, you agree to our publisher agreement
                  </p>

                  <Button
                    label="Create Account"
                    classProperty={`btn_tertiary_1 font_color_tertiary font_normal fs-14 ${styles.continue_button}`}
                    onClick={handleSubmit(createUser)}
                  ></Button>
                  <span>Or</span>

                  <Button
                    onClick={() => setToggle(!toggle)}
                    label="Login"
                    classProperty={`btn_tertiary_1 font_color_tertiary font_normal fs-14 ${styles.continue_button}`}
                  ></Button>
                </div>
                <p className="fs-11 font_color_tertiary font_normal mt-5">
                  Sign up now, and publishing is free forever. Once you start
                  charging for subscriptions, we take 10% in addition to credit
                  card transaction fees.
                </p>
              </div>
            </form>
          </aside>
        </section>
      )}
    </>
  );
};

export default CreateAccount;
