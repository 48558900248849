import { createSlice } from "@reduxjs/toolkit";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { appService } from "../../services/appServices";
import { storageManager } from "../../services/appStorage";
import { CacheKeys } from "../../services/cacheKeys";
const initialState = {
  token: "",
  refreshToken: "",
  user: "",
  userInfo : "",
  stautusCode: null,
  isError: false,
  isSuccess: false,
  isLoading: false,
  password:"",
  message: ""
};

export const login = createAsyncThunk("auth/login", async ({ params, ...data }: any) => {
  const response = await appService.login(data);
  return response
});

export const signUp = createAsyncThunk("auth/signUp", async (data: any) => {
  const response = await appService.signUp(data);
  return response;
});

export const getUserId = createAsyncThunk("auth/getuser", async (id: any) => {
  
  const response = await appService.getUserById(id);

  return response;
});

export const redirectOnSuccess = async (accessToken: string, refreshToken: string, userId: number, params: any) => {
  const data = {
    accessToken, refreshToken, userId, ...params
  }
  await appService.redirectOnSuccess(data)
}


export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    reset() {
      return {
        ...initialState,
      };
    },
    removePublication: (state: any) => ({
      ...state,
      user: {
        ...state.user,
        publicationOwner: null,
      },
    }),
    storePassword(state: any, action: any) {
      // console.log("action",action.payload)
      state.password = action.payload;
    },
    updatePublicationData: (state: any, action: any): any => ({
      ...state,
      user: {
        ...state.user,
        publicationOwner: action.payload.response,
      },
    }),
    updateUserData: (state: any, action: any): any => ({
      ...state,

      user: action.payload,
    }),
  },
  extraReducers: (builder: any) => {
    builder

      .addCase(login.pending, (state: any) => {
        state.isLoading = true;
      })
      .addCase(login.fulfilled, (state: any, action: any) => {

        state.isLoading = false;

        if (
          action.payload.respCode === 403 ||
          action.payload.respCode === 400 ||
          action.payload.respCode === 500 ||
          action.payload.respCode === 404 ||
          action.payload.respCode === undefined
        ) {
          state.isError = true;
          state.message = action.payload.errMessage.message;
        } else if (action.payload.response.statusCode === 401) {
          state.isError = true;
          state.message = action.payload.response.message;
        } else {
          state.isSuccess = true;
          state.isError = false;
          state.token = action.payload.response.accessToken;
          state.refreshToken = action.payload.response.refreshToken;
          state.user = action.payload.response;
          storageManager.save(
            CacheKeys.TOKEN,
            action.payload.response.accessToken
          );
          storageManager.save("refresh", action.payload.response.refreshToken);
        }
      })
      .addCase(login.rejected, (state: any, action: any) => {
        state.isLoading = false;
        state.isError = action.payload;

        state.user = {};
      })
      //getuser
      .addCase(getUserId.pending, (state: any) => {
        state.isLoading = true;
      })

      .addCase(getUserId.fulfilled, (state: any, action: any) => {

        state.isLoading = false;
        // state.user = action.payload.response;
        state.userInfo = action.payload.response;
      })
      //signUp
      .addCase(signUp.pending, (state: any) => {
        state.isLoading = true;
      })

      .addCase(signUp.fulfilled, (state: any, action: any) => {

        state.isLoading = false;

        if (
          action.payload.respCode === 403 ||
          action.payload.respCode === 400 ||
          action.payload.respCode === 409 ||
          action.payload.respCode === 500 ||
          action.payload.respCode === undefined
        ) {
          state.message = action.payload.errMessage.message;
          state.isError = true;
          // state.user = action.payload.response
        } else {
          // state.isSuccess = true;

          // state.token = action.payload.response.accessToken;
          // state.refreshToken = action.payload.response.refreshToken;
          // state.user = action.payload.response.userId;
          // storageManager.save(
          //   CacheKeys.TOKEN,
          //   action.payload.response.accessToken
          // );
          // storageManager.save("refresh", action.payload.response.refreshToken);

          // state.user = action.payload.response;
        }
      });
  },
});

export const {
  reset,
  removePublication,
  updatePublicationData,
  updateUserData,
  storePassword,
} = authSlice.actions;
export default authSlice.reducer;
