import React, { lazy, Suspense } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import { Loader } from "./components";

import CreateAccount from "./pages/createAccount";
import CreatePublication from "./pages/createPublication";

import CategoryList from "./pages/categoryList";

function App() {
  return (
    <>
      <Suspense fallback={<Loader />}>
        <Routes>
          <Route path="/" element={<CreateAccount />} />
          <Route path="/callback" element={<h1>Hello</h1>} />
          <Route path="/Publication" element={<CreatePublication/>}/>
          <Route path='/categorylist' element={<CategoryList/>}/>
        </Routes>
      </Suspense>
    </>
  );
}

export default App;
