import styles from "../styles/pages/CreatePublication.module.scss";
import React, { useState, useEffect, lazy } from "react";
import isFieldEmpty from "../components/validate";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { appService } from "../services/appServices";
import { getUserId } from "../store/user/userSlice";
const PrimaryLayout = lazy(() => import("../components/PrimaryLayout"));
const Button = lazy(() => import("../components/Button"));
const Description = lazy(() => import("../components/Description"));

const CreatePublication = () => {
  const [showNotification, setShowNotification]: any = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch<any>();
  const { user , password }: any = useSelector<any>((state: any) => state.auth);
  const [showMenu, setShowMenu]: any = useState(false);
  const [error, setError]: any = useState({});
  const [publicationData, setPublicationData] = useState({
    name: "",
    domain: "",
    tagLine: "",
  });
  const returnUrl = () => {
    const domainBySite = window.location.host.split(".")[0];
    const path = window.location.pathname;
    let domain =
      window.location.hostname === "localhost" ? "tvow-dev" : domainBySite;
    const ref = window.location.host.replace(`${domain}.`, "");
    const protocol = window.location.protocol.replace(/:$/, "");
    appService.redirectToAuth(ref, domain, protocol, path);
  };

  // useEffect(() => {
  //   if (!localStorage.getItem("userIdNumber")) {
  //     returnUrl();
  //   }
  // }, []);
  // useEffect(() => {
  //   if (user && user?.publicationOwner ) {
  //     navigate(`/story`);
  //   }
  // }, [user?.publicationOwner]);
  const [toggle, setToggle] = useState(false);
  const checkValidations = (value?: any) => {
    let flag = true;

    let data = { ...error };

    if (isFieldEmpty(publicationData.name, "Name", setError)) flag = false;

    if (isFieldEmpty(publicationData.domain, "Domain", setError)) flag = false;
    if (isFieldEmpty(publicationData.tagLine, "tagLine", setError))
      flag = false;

    return flag;
  };

  const submitPublication = async () => {
   
     
      setToggle(true);
    
    // } else {
    //   navigate(`/createAccount?returnUrl=${window.location.pathname}`);
    //   // {
    //   //   pathname: "/createAccount",
    //   //   query: { returnUrl: window.location.pathname },
    //   // }
    // }
  };
useEffect(()=>{
dispatch(getUserId(user?.userId));
},[])
  return (
    <div>
      {!toggle ? (
        <div
          onClick={() => {
            setShowMenu(false);
            setShowNotification(false);
          }}
        >
          <PrimaryLayout
            showNotification={showNotification}
            setShowNotification={setShowNotification}
            showMenu={showMenu}
            setShowMenu={setShowMenu}
            isAuthor={true}
          />
          <div className="container_width pt-28 h-full flex gap-8 justify-center">
            <aside className=" basis-1/4 ">
              <div>
                <div
                  className={`${styles.create_border} mt-5 gap-y-3 flex flex-col items-center p-7 justify-between `}
                >
                  <p className="fs-24 mt-5 font_semibold w-full flex justify-center font_color_common">
                    Create New Publication
                  </p>
                  <div className="w-full">
                    <p className="fs-14 font_medium font_color_common my-2">
                      Publication Name
                    </p>
                    <input
                      type="text"
                      className="w-full p-2 border-[0.5px] border-[#FFC5D6] text-sm text-[#757575] outline-0 opacity-100"
                      placeholder="The Voice of Woman"
                      value={publicationData.name}
                      onChange={(e) =>
                        setPublicationData({
                          ...publicationData,
                          name: e.target.value,
                        })
                      }
                    />
                    {error && <p className="text-danger">{error["Name"]}</p>}
                  </div>

                  <div className="w-full ">
                    <p className="fs-14 font_medium font_color_common my-2">
                      Tagline
                    </p>
                    <input
                      type="text"
                      className="w-full p-2 border-[0.5px]  border-[#FFC5D6]  text-sm text-[#757575] outline-0 opacity-100"
                      placeholder="Eg Women's voice around the world"
                      value={publicationData.tagLine}
                      onChange={(e) =>
                        setPublicationData({
                          ...publicationData,
                          tagLine: e.target.value,
                        })
                      }
                    />
                    {error && <p className="text-danger">{error["tagLine"]}</p>}

                    <div
                      className={`w-full relative   flex items-center`}
                    ></div>
                    <p className="fs-14 font_medium font_color_common my-2">
                      Publication URL
                    </p>
                    <div className="flex items-center justify-start gap-x-2">
                      <input
                        type="text"
                        className="w-[40%] rounded p-2 border-[0.5px] border-[#FFC5D6] text-sm text-[#251D19] outline-0 opacity-100 placeholder-[#251D19]"
                        placeholder="Appsierra"
                        value={publicationData.domain}
                        onChange={(e) =>
                          setPublicationData({
                            ...publicationData,
                            domain: e.target.value,
                          })
                        }
                      />
                      {error && (
                        <p className="text-danger">{error["Domain"]}</p>
                      )}
                      <div className="flex gap-x-1 items-center">
                        <p className="text-sm font_color_common tetx-[#251D19] ">
                          .thevoiceofwoman.com
                        </p>
                        <img
                          src={"/images/Group 448.png"}
                          className="object-contain h-[13px] w-[13px]"
                        ></img>
                      </div>
                    </div>

                    <div
                      className={`w-full relative   flex items-center`}
                    ></div>
                  </div>

                  <Button
                    label="Next"
                    classProperty={`btn_tertiary_1 font_color_tertiary mt-8 font-medium fs-14 ${styles.continue_button}`}
                    onClick={() => submitPublication()}
                  ></Button>
                </div>
              </div>
            </aside>
          </div>
        </div>
      ) : (
        <>
          <Description
            Name={publicationData.name}
            TagLine={publicationData.tagLine}
            Domain={publicationData.domain}
          />
        </>
      )}
    </div>
  );
};

export default CreatePublication;
