// import Image from "next/image";

interface IImage {
  media: {
    url: string;
    alternativeText?: string;
  };
  className?: string;
  width?: number;
  height?: number;
  layout?: any;

  alt?: string;
}

const ImageTag = (props: IImage) => {
  const { url } = props.media;
  const { className, width, height, alt,layout } = props;

  return (
    <img
      src={url || '/images/vow_logo.png'}
      alt={alt}
      className={className}
      width={width || 0}
      height={height || 0}
      // layout={layout}
     
    />
  );
};

export default ImageTag;
